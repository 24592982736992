.button {
  background: none;
  text-decoration: none;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  color: var(--white);

  &-primary {
    background: var(--blue-cyan);
    padding: 5px 10px;

    &__border {
      border: 1px solid var(--blue-cyan);
    }
  }

  &-ghost {
    &__border {
      border: 1px solid var(--white);
    }

    &-hover-cyan:hover {
      background: var(--blue-cyan);
    }
  }

  &--fluid {
    width: 100%;
  }

  &--large {
    min-height: 40px;
    min-width: 200px;
    font-size: 18px;
  }

  &--medium {
    min-height: 35px;
    min-width: 150px;
    font-size: 16px;
  }

  &--small {
    min-width: 100px;
  }

  &--xs {
    min-width: 50px;
  }

  &--xxs {
    width: 35px;
  }
}