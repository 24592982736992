.carousel {
  width: 100%;
  text-align: center;

  .controls {
    cursor: pointer;
  }
}

.item_container {
  padding: 25px;
  height: 450px;
  width: 300px;
  display: flex;
  align-items: center;
  text-align: center;
}

.item {
  display: flex;
  flex-direction: column-reverse;
  height: 400px;
  width: 250px;
  padding: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  text-align: left;
  border-radius: 20px;

  &__order {
    font-size: 30px;
    color: var(--blue-sky);
  }

  &__content {
    font-size: 18px;
    font-weight: 600;
    width: 75%;
    color: var(--white);
  }

  &__01 {
    background-image: url('../../media/img/services/1.png');
  }
  &__02 {
    background-image: url('../../media/img/services/2.png');
  }
  &__03 {
    background-image: url('../../media/img/services/3.png');
  }
  &__04 {
    background-image: url('../../media/img/services/4.png');
  }
  &__05 {
    background-image: url('../../media/img/services/5.png');
  }
  &__06 {
    background-image: url('../../media/img/services/6.png');
  }
  &__07 {
    background-image: url('../../media/img/services/7.png');
  }
  &__08 {
    background-image: url('../../media/img/services/8.png');
  }
  &__09 {
    background-image: url('../../media/img/services/9.png');
  }
  &__10 {
    background-image: url('../../media/img/services/10.png');
  }
  &__11 {
    background-image: url('../../media/img/services/11.png');
  }
  &__12 {
    background-image: url('../../media/img/services/12.png');
  }
  &__13 {
    background-image: url('../../media/img/services/13.png');
  }
  &__14 {
    background-image: url('../../media/img/services/14.png');
  }
  &__15 {
    background-image: url('../../media/img/services/15.png');
  }
  &__16 {
    background-image: url('../../media/img/services/16.png');
  }
}

.item:hover {
  height: 440px;
  width: 290px;
}

.alice-carousel__prev-btn {
  position: absolute;
  top: 50%;
}


.alice-carousel__prev-btn {
  text-align: left !important;
}

.alice-carousel__next-btn-item {
  background: #ec0;
}

.prev-button {
  position: absolute;
  top: 50%;
}