.packages {
  padding: 5% 10%;
  text-align: center;

  &__title {
    color: var(--blue-honolulu);
    font-size: 30px;
    margin-bottom: 20px;
  }

  .content {
    display: flex;
    justify-content: space-around;
    text-align: center;
    margin: 0 auto;
  }

  .main-package {
    width: 100%;
  }

  .package {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 200px;
    width: 250px;
    background: var(--grey-light);
    border-radius: 20px;
    margin: 0 auto;

    &__title {
      color: var(--blue-cyan);
      font-size: 30px;
      margin-bottom: 25px;
    }
  }
}

@media(max-width:1024px) {
  .packages {
    padding: 5%;
  }
}

@media(max-width:870px) {
  .packages {
    padding: 5% 0;

    .content {
      width: 100%;
      text-align: center;
    }

    .main-package {
      width: auto;
    }

    .package {
      height: 150px;
      width: 200px;
    }
  }
}

@media(max-width:670px) {
  .packages {
    padding: 5%;

    .content {
      width: 100%;
      margin: 0 auto;
    }

    .package {
      margin: 0 auto;
      padding: 10px;
      height: 250px;
      width: 60vw;
      min-width: 186px
    }
  }
}
