.strengths {
  width: 100%;
  background: var(--grey-light);
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 70px 15%;


  &__title {
    color: var(--blue-honolulu);
    font-size: 30px;
    margin-bottom: 20px;
  }

  &__content {
    color: var(--grey);
    font-size: 12px;
  }

  &__paragraph {
    font-size: 16px;
    margin-bottom: 10px;

    span {
      color: var(--blue-honolulu);
      font-weight: 600;
    }
  }
}
