.icons {
  display: flex;
  margin: 5px 0;
}

.icon {
  height: 100%;
  margin: 0 10px;
  padding-top: 5px;
}
