.footer {
  display: flex;
  justify-content: space-between;
  background: var(--blue-honolulu);
  color: var(--white);
  padding: 5% 7%;

  &__form {
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  &__info {
    display: flex;
    flex-direction: column;
    padding: 0 30px;
    width: 50%;
  }

  &__md-cont {
    display: flex;
    justify-content: space-between;
  }

  &__form-title {
    font-size: 28px;
    color: var(--blue-sky);
    font-weight: 600;
    margin-bottom: 25px;
  }

  &__input,
  &__textarea {
    background: none;
    border: 2px solid var(--white);
    color: var(--blue-sky);
    border-radius: 20px;
    padding: 5px 10px;
    width: 100%;
    margin-bottom: 10px;
  }

  &__input--half {
    width: 48%;
  }

  &__input::placeholder,
  &__textarea::placeholder {
    color: var(--blue-sky);
  }

  &__submit {
    width: 100%;
    border: none;
    border-radius: 20px;
    background: var(--blue-cyan);
    color: var(--white);
    height: 40px;
    font-size: 18px;
    cursor: pointer;
  }

  input:focus,
  select:focus,
  textarea:focus {
    outline: none;
  }

  .description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 0 10px;
    margin-bottom: 30px;

    .logo {
      margin-bottom: 30px;
    }

    &__content {
      font-size: 11px;
    }
  }

  .contact {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 10px;

    &__content {
      display: flex;
      flex-direction: column;
      
      span,
      a {
        margin-bottom: 5px;
        font-size: 14px;
      }
    }
  }

  .title {
    color: var(--blue-sky);
    margin-bottom: 15px;
  }

  .icons {
    margin: 10px 0;
  }

  .icon {
    margin: 0 10px;
  }
}

@media(max-width:920px) {
  .footer {
    flex-direction: column;
    align-items: flex-start;

    &__form,
    &__info {
      width: 100%;
      padding: 0;
      margin-bottom: 20px;
    }

    .description {
      width: 100%;
      margin-bottom: 50px;

      &__content {
        font-size: 14px;
      }
    }
  
    .contact {
      width: 100%;
      justify-content: space-between;

      .column {
        margin-bottom: 30px;
      }
    }
  }
}

@media(max-width:512px) {
  .footer {
    &__md-cont {
      flex-direction: column;
    }

    .description {
      flex-direction: column;
    }

    .contact {
      flex-direction: column;
      justify-content: space-around;
    }
  }
}
