@import "./globals";
@import "react-alice-carousel/lib/scss/alice-carousel.scss";

.alice-carousel {
  z-index: 10;
  text-align: center;
  padding: 10px;
}

.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  width: auto;
  position: absolute;
  bottom: 50%;
}

.alice-carousel__prev-btn {
  text-align: left;
  left: -2%;
}

.alice-carousel__next-btn {
  text-align: right;
  right: -2%;
}
