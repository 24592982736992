.whats-button {
  display: flex;
  position: fixed;
  bottom: 30px;
  right: 15px;
  align-items: center;
  z-index: 30;
  background: var(--green);
  padding: 5px 15px;
  border-radius: 30px;

  &__link {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  &__icon {
    margin-right: 5px;
  }
}