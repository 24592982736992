.certifications {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 5%;
  width: 100%;

  &__title {
    color: var(--blue-honolulu);
    font-size: 30px;
    margin-bottom: 20px;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__item {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  &__image {
    margin-bottom: 40px;
  }

  &__content {
    font-size: 14px;
    font-weight: 300;
  }
}

@media(max-width:800px) {
  .certifications {

    &__container {
      flex-direction: column;
      align-items: center;
    }

    &__item {
      width: 100%;
      margin-bottom: 30px;
    }
  }
}

@media(max-width:500px) {
  .certifications {
    padding: 50px 5%;
  }
}