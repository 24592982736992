:root {
  --white: #FFFFFF;
  --black: #000;
  --black-opac: rgba(0, 0, 0, 0.8);
  --grey: #4D4D4D;
  --grey-light: #F2F2F2;
  --blue-honolulu: #0568B6;
  --blue-cyan: #0AB0E1;
  --blue-sky: #82E6F3;
  --green: #25d366;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif;
}

button {
  background: none;
  text-decoration: none;
  border: none;
  cursor: pointer;
}

.button-primary {
  background: var(--blue-cyan);
  color: var(--white);
  border-radius: 20px;
  padding: 5px 10px;

  &__large {
    min-height: 40px;
    min-width: 200px;
    font-size: 18px;
  } 

  &__medium {
    min-height: 35px;
    min-width: 150px;
    font-size: 16px;
  }

  &__small {
    min-width: 100px;
  }

  &__tiny {
    min-width: 50px;
  }
}

a,
a:link,
a:visited,
a:hover,
a:active { 
  text-decoration: none;
  color: var(--white);
}
