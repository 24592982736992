.services {
  background: var(--grey-light);
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 5%;

  &__title {
    color: var(--blue-honolulu);
    font-size: 30px;
    margin-bottom: 20px;
  }

  &__subtitle {
    color: var(--grey);
    font-size: 12px;
  }

  .advice {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
  }

  .alice-carousel__prev-btn {
    position: absolute;
    top: 50%;
  }
}