.nav {
  width: 100%;
  display: flex;

  .container {
    width: auto;
  }

  .button {
    border-radius: 20px;
    padding: 5px 10px;
    margin: 0 3%;
    min-height: 35px;
    min-width: 150px;
    font-size: 16px;
  }

  .button:hover {
    background: var(--blue-cyan);
  }
}

.vertical {
  display: flex;
  flex-direction: column;

  .link {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__button {
    width: 100%;
    text-align: center;
    margin: 20px 0;
    border-bottom: 1px solid var(--white);
  }
}

@media(max-width:730px) {

  .nav {
    width: 100%;
    display: flex;
  
    .container {
      width: 100%;
    }
    
  }

  .vertical {
    button {
      width: 100%;
    }
  }
}