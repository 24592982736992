.special-programs {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: var(--blue-cyan);
  padding: 5%;
  color: var(--white);

  .title-container {
    display: flex;
    width: 20%;
    text-align: center;
    align-items: center;
    font-size: 30px;
  }

  .wrapper-programs {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 75%;
    .column {
      width: 48%;
    }
  }
  .program {
    padding: 10px 15px;
    border-bottom: 1px solid var(--white);

    li {
      padding: 5px 0;
    }
  }

  .program:hover {
    background: var(--blue-sky);
  }
}

@media(max-width:920px) {
  .special-programs {
    flex-direction: column;

    .title-container {
      width: 100%;
      text-align: center;
      margin-bottom: 30px;

      h3 {
        margin: 0 auto;
      }
    }

    .wrapper-programs {
      width: 100%;
    }
  }
}

@media(max-width:512px) {
  .special-programs {
    .wrapper-programs {
      flex-direction: column;
      margin-bottom: 50px;

      .column {
        width: 100%;
      }
    }
  }
}

// @media(max-width:512px) {
//   .special-programs {

//     .wrapper-programs {
//       width: 100%;
//     }
//   }
// }