.header {
  width: 100%;
  height: 100vh;
  background-image: url('../../media/img/header/header-corehlance.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: var(--white);
  padding: 65px 40px;

  .top-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .logo {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  .translations {
    display: flex;
    min-width: 90px;
    align-items: center;
    .button-translation {
      margin: 0 3px;
      background: none;
      padding: 3px 5px;
      color: var(--white);
      border-radius: 20px;

      &--current {
        border: 1px solid var(--white);
      }
    }
  }

  .menu {
    margin-right: 20px;
  }

  .content {
    text-align: left;
    max-width: 450px;
    bottom: 40vh;
    margin-top: 40vh;

    p {
      margin-bottom: 10px;
    }
  }

  .title {
    font-size: 42px;
    line-height: 45px;
    font-weight: 700;
  }

  .big_menu {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 100%;
    position: fixed;
    background: var(--blue-sky);
    padding: 50px 40px;
    transition: all 0.5s;
    z-index: 20;

    &--active {
      left: 0;
    }
    

    .button_menu {
      display: flex;
      flex-direction: row-reverse;
      width: 100%;
      margin-bottom: 20px;
    }
  }
}

@media(max-width:420px) {
  .header {
    padding-top: 85px;
    padding: 85px 20px;

    .content {
      margin-top: 30vh;
    }
  }
}