.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  width: 100vw;
  height: 100vh;
  background: var(--black-opac);
  position: fixed;
  z-index: 50;

  .container {
    width: 70%;
    height: 90%;
    background: var(--white);
    padding-top: 20px;
    border-radius: 20px;
  }

  .terms-and-conditions {
    width: 100%;
    height: 90%;
    margin: 0 auto;
    overflow-y: visible;
    overflow-x: hidden;

    &__pdf-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;

      div {
        display: flex;
        justify-content: center;
      }
    }
  }

  .controls {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 80% !important;
  height: 100% !important;
}

@media(max-width:512px) {
  .modal {
    .container {
      width: 90%;
      height: 95%;
    }
  }
}

@media(max-width:300px) {
  .modal {
    .controls {
      display: flex;
      flex-direction: column;
      justify-items: center;
      justify-content: center;
    }
    .terms-and-conditions {
      height: 85%;
    }

    button {
      margin-bottom: 5px;
    }
  }
}