.fixed-contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  background: var(--blue-cyan);
  width: 100%;
  padding: 5px 10px;
  top: 0;
  z-index: 20;
  color: var(--white);
  font-size: 14px;

  .contact-text {
    color: var(--blue-sky);
    margin-right: 5px;
  }
}

@media(max-width:420px) {
  .fixed-contact {
    flex-direction: column;
    padding-top: 10px;
  }
}